import AdvertisementsTopCmp from './components/advertisements-top/advertisements-top.cmp.vue'
import AdvertisementsLeftCmp from './components/advertisements-left/advertisements-left.cmp.vue'
import AdvertisementsRightCmp from './components/advertisements-right/advertisements-right.cmp.vue'

export default {
    components: {
        AdvertisementsTopCmp,
        AdvertisementsLeftCmp,
        AdvertisementsRightCmp,
    }
}