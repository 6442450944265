import imageInput from '@/mixins/image-input.js';
import AdvertisementsPostModel from '../../models/advertisements-post.model';
export default {
    data() {
        return {
            item: {
            }
        }
    },
    methods: {
        approve() {
            this.item.adType = 2;
            let data = new AdvertisementsPostModel(this.item);

            let formData = new FormData();
            for (let key in data) {
                formData.append(key, data[key]);
            }

            this.$store.dispatch("advertisements/updateItem", {id: this.item.id, data: formData});
        }
    },
    created() {
        this.$store.dispatch("advertisements/getAdByType", 2).then((res) => {
            this.item = res;
        });
    },

    mixins: [imageInput],
}
